/* poppins fonts */
@font-face {
    font-family: 'poppins';
    src: url('poppins-light.woff2') format('woff2'),
         url('poppins-light.woff') format('woff'),
         url('Poppins-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'poppins';
    src: url('poppins-regular.woff2') format('woff2'),
         url('poppins-regular.woff') format('woff'),
         url('Poppins-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'poppins';
    src: url('poppins-regular-italic.woff2') format('woff2'),
         url('poppins-regular-italic.woff') format('woff'),
         url('Poppins-Regular-Italic.ttf') format('ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'poppins';
    src: url('poppins-medium.woff2') format('woff2'),
         url('poppins-medium.woff') format('woff'),
         url('Poppins-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'poppins';
    src: url('poppins-mediumitalic.woff2') format('woff2'),
         url('poppins-mediumitalic.woff') format('woff'),
         url('Poppins-MediumItalic.ttf') format('ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'poppins';
    src: url('poppins-semibold.woff2') format('woff2'),
         url('poppins-semibold.woff') format('woff'),
         url('Poppins-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'poppins';
    src: url('poppins-semibolditalic.woff2') format('woff2'),
         url('poppins-semibolditalic.woff') format('woff'),
         url('Poppins-SemiBoldItalic.ttf') format('ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'poppins';
    src: url('poppins-bold.woff2') format('woff2'),
         url('poppins-bold.woff') format('woff'),
         url('Poppins-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
}


/* telugu mn */
@font-face {
    font-family: 'Telugu-MN';
    src: url('TeluguMN.woff2') format('woff2'),
        url('TeluguMN.woff') format('woff'),
        url('TeluguMN.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Telugu-MN';
    src: url('TeluguMN-Bold.woff2') format('woff2'),
        url('TeluguMN-Bold.woff') format('woff'),
        url('TeluguMN-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
}